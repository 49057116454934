/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "wearing-hearing-aids-could-prevent-or-delay-45-of-dementia-cases",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wearing-hearing-aids-could-prevent-or-delay-45-of-dementia-cases",
    "aria-label": "wearing hearing aids could prevent or delay 45 of dementia cases permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wearing hearing aids could prevent or delay 45% of dementia cases"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In an ", React.createElement("a", {
    href: "https://www.theguardian.com/society/article/2024/jul/31/almost-half-of-dementia-cases-could-be-prevented-or-delayed-study-finds",
    target: "_blank"
  }, "interview with the Guardian"), ", Prof Gill Livingston, lead author of the research, emphasized that there are substantial opportunities for millions of people to mitigate the risk of dementia."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Speaking from a conference in Philadelphia, Livingston stated, “Many people globally believe dementia is inevitable, but it’s not. Our report concludes that individuals can significantly enhance their chances of avoiding dementia or delaying its onset.\""), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "\"It's crucial to note,\" she continued, \"that while longer exposure to risks exacerbates their impact, it’s never too early or too late to take action.\""), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Livingston, from University College London, added that people of all ages, from children to the elderly, can take proactive steps to reduce their dementia risk, a disease currently without a cure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Almost half of dementia cases worldwide could be prevented or delayed, according to the study, as experts identified 14 key risk factors. Addressing these modifiable risk factors, starting in childhood and continuing throughout life, could prevent or delay 45% of dementia cases, even as people live longer, the ", React.createElement("a", {
    href: "https://www.thelancet.com/commissions/dementia-prevention-intervention-care",
    target: "_blank"
  }, "Lancet commission on dementia"), " reported. The findings were presented at the Alzheimer’s Association international conference in the US."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The number of people living with dementia globally is forecast to nearly triple to 153 million by 2050, and researchers warn that this presents a rapidly growing threat to health and social care systems. Global health and social costs linked to dementia exceed $1 trillion (£780 billion) a year, the research shows."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the latest findings, the report identifies two new risk factors contributing to 9% of dementia cases. Approximately 7% of cases are linked to high midlife levels of low-density lipoprotein (LDL) cholesterol, beginning around age 40, while 2% are associated with untreated vision loss later in life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "These additions complement the 12 risk factors identified by the Lancet Commission in 2020, which collectively account for about 36% of dementia cases, Livingston explained."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The experts underscored in their report, \"The potential for prevention is substantial, with nearly half of all dementia cases theoretically preventable by addressing these 14 risk factors. These findings offer hope.\""), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Livingston highlighted new evidence indicating that reducing dementia risks not only extends healthy years of life but also reduces the duration of dementia-related ill health."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "\"Healthy lifestyles incorporating regular exercise, non-smoking, ongoing cognitive engagement beyond formal education, and moderate alcohol consumption can not only lower dementia risk but potentially delay its onset,\" she emphasized."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This approach could also reduce the duration of dementia among those affected, Livingston noted, emphasizing, \"This has profound implications for quality of life and significant cost savings for societies.\""), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "She suggested that incorporating some form of physical activity into daily routines, even simple activities like walking or seated exercises, could notably reduce dementia risk, especially for sedentary individuals."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To address dementia risk comprehensively across the lifespan, the Lancet Commission put forward 13 recommendations. These include improving accessibility to hearing aids and reducing harmful noise exposure, starting screening and treatment for high cholesterol around age 40, ensuring accessible vision impairment services, and providing quality education and cognitive engagement in midlife."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A separate study in the Lancet Healthy Longevity journal, alongside the Commission's report, modeled the economic benefits of implementing these recommendations in England, projecting potential savings of £4 billion by reducing dementia rates and promoting longer, healthier lives."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Fiona Carragher, chief policy and research officer at Alzheimer’s Society, which co-funded the study, stressed, \"While lifestyle changes can address some dementia risk factors like alcohol and exercise, societal factors such as social isolation, education disparities, and air pollution require broader public health interventions and collaborative efforts from government and industry.\""), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Dr Susan Kohlhaas, executive director of research at Alzheimer’s Research UK, cautioned that age and genetics remain primary dementia risk factors. However, she viewed the identification of additional modifiable health and lifestyle factors as positive news, presenting a significant opportunity for individuals and governments to implement preventive measures and reduce dementia's future impact on society and families."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In a seismic report published by the Lancet, 27 of the world’s leading dementia experts concluded that far more cases could be avoided or delayed than previously thought."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-hearcom-if-you-or-someone-you-know-might-have-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-if-you-or-someone-you-know-might-have-hearing-loss",
    "aria-label": "contact hearcom if you or someone you know might have hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact hear.com if you or someone you know might have hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " offers comprehensive solutions tailored to your needs. Our process is designed to be simple and accessible, ensuring you get the help you need without hassle."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our process is straightforward:"), "\n", React.createElement(_components.ol, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Fill out the questionnaire on our website."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Receive a call from a hearing expert who will guide you through your options."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Get your hearing aids delivered to your home in 48 hours or less."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Visit an audiologist near your location for a professional fitting."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Try out your hearing aids for 45 days to ensure they meet your needs."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With a commitment to accessibility and quality, hear.com provides hearing aids to fit any budget. Our network includes the top 2% of audiologists in the United States, making sure you receive expert care and personalized support. We are here to help you hear well to live well."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
